var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "xl",
            scrollable: "",
            title: "Formulario de configuración de propuesta Ingenes",
            "no-close-on-backdrop": "",
            "hide-header-close": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "justify-content-end buttons-dropdown" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn btn-blue-dark-outline mr-3",
                              on: { click: _vm.handleCloseModal }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("admin.users.cancel")) + " "
                              )
                            ]
                          ),
                          !_vm.isEdit
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "btn btn-blue-dark-outline mr-3",
                                  on: { click: _vm.clearProgramForm }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("admin.discounts.clear")) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn btn-blue-dark ml-0",
                              attrs: {
                                disabled: _vm.isLoadingDone || !_vm.validateForm
                              },
                              on: { click: _vm.handleConfig }
                            },
                            [
                              !_vm.isLoadingDone
                                ? _c(
                                    "span",
                                    [
                                      _vm.isEdit
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("admin.role.edit"))
                                            )
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("admin.role.create")
                                              )
                                            )
                                          ]),
                                      _c("b-icon", {
                                        staticClass: "icon ml-2",
                                        attrs: {
                                          icon: "arrow-right",
                                          "aria-hidden": "true"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "span",
                                    [
                                      _c("b-spinner", {
                                        staticClass: "align-middle",
                                        attrs: { small: "" }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("admin.medicines.loading")
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.modalShow,
            callback: function($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow"
          }
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "label",
                    { staticClass: "mt-3 font-weight-bold d-block" },
                    [_vm._v(_vm._s(_vm.$t("admin.role.country")) + " *")]
                  ),
                  _c("b-form-select", {
                    staticClass: "mt-0 select",
                    attrs: {
                      options: _vm.optionsCountry,
                      size: "sm",
                      disabled: _vm.isEdit || _vm.isClone
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: 0, disabled: "" } },
                              [
                                _vm._v(
                                  "-- " +
                                    _vm._s(_vm.$t("admin.role.selectCountry")) +
                                    " --"
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.formDiscount.pais,
                      callback: function($$v) {
                        _vm.$set(_vm.formDiscount, "pais", $$v)
                      },
                      expression: "formDiscount.pais"
                    }
                  }),
                  _c(
                    "label",
                    { staticClass: "mt-3 font-weight-bold d-block" },
                    [_vm._v(_vm._s(_vm.$t("admin.discounts.name")) + " *")]
                  ),
                  _c("b-form-input", {
                    attrs: {
                      id: "name-input",
                      type: "text",
                      size: "sm",
                      lazy: "",
                      placeholder: this.$i18n.t(
                        "admin.discounts.nameConfiguration"
                      )
                    },
                    model: {
                      value: _vm.formDiscount.nombre,
                      callback: function($$v) {
                        _vm.$set(_vm.formDiscount, "nombre", $$v)
                      },
                      expression: "formDiscount.nombre"
                    }
                  }),
                  _c(
                    "label",
                    { staticClass: "mt-3 font-weight-bold d-block" },
                    [_vm._v("Unidad de negocio Hubspot *")]
                  ),
                  _c("b-form-select", {
                    staticClass: "mt-0 select",
                    attrs: {
                      options: _vm.optionsUnidadNegocio,
                      size: "sm",
                      disabled: _vm.isEdit || _vm.isClone
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { disabled: "" } },
                              [_vm._v("-- Selecciona la unidad de negocio --")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.formDiscount.unidad_de_negocio,
                      callback: function($$v) {
                        _vm.$set(_vm.formDiscount, "unidad_de_negocio", $$v)
                      },
                      expression: "formDiscount.unidad_de_negocio"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("hr"),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("label", { staticClass: "mt-3 font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.$t("admin.branchMatriz.category")) + " *")
                  ]),
                  !_vm.isLoadingDone
                    ? _c("b-form-select", {
                        staticClass: "mt-0 select",
                        attrs: { options: _vm.optionsCategory, size: "sm" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null, disabled: "" } },
                                    [
                                      _vm._v(
                                        "-- " +
                                          _vm._s(
                                            _vm.$t(
                                              "admin.discounts.selectCategory"
                                            )
                                          ) +
                                          " --"
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3527839709
                        ),
                        model: {
                          value: _vm.formDiscount.categoria,
                          callback: function($$v) {
                            _vm.$set(_vm.formDiscount, "categoria", $$v)
                          },
                          expression: "formDiscount.categoria"
                        }
                      })
                    : _c(
                        "div",
                        [_c("b-spinner", { staticClass: "align-middle" })],
                        1
                      )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("label", { staticClass: "mt-3 font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.$t("admin.discounts.product")) + " *")
                  ]),
                  !_vm.isLoadingDone
                    ? _c("b-form-select", {
                        staticClass: "mt-0 select",
                        attrs: {
                          options: _vm.optionsProductXCategory,
                          size: "sm"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null, disabled: "" } },
                                    [
                                      _vm._v(
                                        " -- " +
                                          _vm._s(
                                            _vm.$t(
                                              "admin.discounts.selectProduct"
                                            )
                                          ) +
                                          " -- "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: "all" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "admin.discounts.allProducts"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          384038917
                        ),
                        model: {
                          value: _vm.formDiscount.producto,
                          callback: function($$v) {
                            _vm.$set(_vm.formDiscount, "producto", $$v)
                          },
                          expression: "formDiscount.producto"
                        }
                      })
                    : _c(
                        "div",
                        [_c("b-spinner", { staticClass: "align-middle" })],
                        1
                      )
                ],
                1
              )
            ],
            1
          ),
          _c("hr")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }