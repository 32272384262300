<template>
  <div class="rol">
    <h3>Propuesta Ingenes</h3>
    <div role="group" class="card-discount">
      <b-row class="mb-4 align-items-center text-align-end">
        <b-col cols="3">
          <!-- <b-input-group size="md">
            <b-form-input
              id="input-fecha-vigencia"
              v-model="vigencyDate"
              type="date"
              class="custom-input"
              :placeholder="this.$i18n.t('admin.discounts.search')"
            >
            </b-form-input>
          </b-input-group> -->
        </b-col>
        <b-col cols="2">
          <!-- <b-button variant="outline-primary" class="button btn-blue-dark ml-0" size="sm" @click="saveVigencyDate">
            {{ $t('admin.discounts.saveVigency') }}
          </b-button> -->
        </b-col>
        <b-col cols="1"></b-col>
        <b-col>
          <b-input-group size="md">
            <b-form-input
              id="filter-input-discount"
              v-model="filterDiscount"
              type="search"
              class="custom-input"
              placeholder="Buscar propuesta"
            >
            </b-form-input>
          </b-input-group>
          <b-icon icon="search" aria-hidden="true" flip-h class="input__search__icon"></b-icon>
        </b-col>
        <b-col>
          <b-button
            variant="outline-primary"
            class="button btn-blue-dark ml-0"
            @click="newProgramConfiguration"
            v-if="configRol.administrador.pestana.adminDiscounts.acciones.crear"
          >
            Crear Configurción
          </b-button>
        </b-col>
      </b-row>
      <template v-if="configRol.administrador.pestana.adminDiscounts.acciones.listar">
        <b-table
          :items="itemsIngenes"
          :fields="fields"
          :filter="filterDiscount"
          :filter-included-fields="filterOn"
          class="mt-5"
          show-empty
          thead-tr-class="headerClass"
          :busy="isLoadingBussinessList"
        >
          <template #cell(actions)="row">
            <b-row>
              <b-button
                size="sm"
                class="mr-2 bg-success"
                @click="editConfigProgram(row.item)"
                v-if="configRol.administrador.pestana.adminDiscounts.acciones.editar"
              >
                {{ $t('admin.role.edit') }}
              </b-button>
              <b-button
                size="sm"
                class="mr-2 bg-danger"
                @click="deleteConfigProgram(row.item._id)"
                v-if="configRol.administrador.pestana.adminDiscounts.acciones.eliminar"
              >
                {{ $t('admin.role.delete') }}
              </b-button>
              <b-form-checkbox
                v-model="row.item.estado"
                @change="changeStatus(row.item)"
                name="check-button"
                switch
                v-if="configRol.administrador.pestana.adminDiscounts.acciones.editar"
              >
              </b-form-checkbox>
            </b-row>
          </template>
          
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
        <div class="btn-save-discounts">
          <b-button
            variant="outline-primary"
            class="button btn-blue-dark ml-0"
            size="sm"
            @click="saveAllDiscounts"
            :disabled="isLoadingBussinessList || !updateInfo"
          >
            Guardar propuesta
          </b-button>
        </div>
      </template>
      <template v-else>
        <b-alert show dismissible>
          <p>
            {{ $t('admin.bussiness.msgUnable') }}
          </p>
        </b-alert>
      </template>
    </div>
    <!-- MODAL CONFIGURACION -->

    <ModalConfigIngenes
      :modalShow="modalShowDiscount"
      @closeModal="closeModalDiscount"
      :getListConfiguration="getListConfiguration"
      :isEdit="isEditConfig"
      :configurationForm="configurationForm"
      :isClone="isCloneConfig"
    />
  </div>
</template>

<script>
import { API_ROOT } from '../../../Constants/Api';
import { AlertConfirm, AlertForm, ICON } from '../../../helpers/Alert';
import { ApiRequest } from '../../../helpers/ApiRequest';
import ModalConfigIngenes from './ModalConfigIngenes.vue';
export default {
  name: 'IngenesListAdmin',
  components: { ModalConfigIngenes },
  data: () => ({
    vigencyDate: null,
    isLoadingBussinessList: false,
    itemsIngenes: [],
    filterDiscount: null,
    filterOn: [],
    checked: false,
    modalShowDiscount: false,
    isEditConfig: false,
    configurationForm: null,
    configRol: {},
    isCloneConfig: false,
    configDiscountUpdate: {},
    updateInfo: false,
  }),
  created() {
    this.configRol = this.$store.state.configRol;
    if (this.configRol.administrador.pestana.adminDiscounts.permiso === false) {
      this.$router.push({ name: 'Welcome' });
      AlertForm(
        `${this.$i18n.t('permissions.unauthorized')} ${this.$i18n.t('admin.discounts.title')}`,
        this.$i18n.t('permissions.validateAdmin'),
        ICON.WARNING,
        'center',
        null
      );
    }
  },
  async mounted() {
    await this.getListConfiguration();
    
  },
  computed: {
    fields() {
      return [
        {
          key: 'cfg_pais',
          label: this.$i18n.t('admin.discounts.country'),
        },
        {
          key: 'cfg_buyer',
          label: 'Buyer Persona',
        },
        {
          key: 'cfg_idPerfil',
          label: 'Perfil'
        },
        {
          key: 'cfg_score',
          label: 'Score',
        },
        {
          key: 'cfg_subScore',
          label: 'Sub score',
        },
        {
          key: 'actions',
          label: this.$i18n.t('admin.role.actions'),
        },
      ];
    },
  },
  methods: {
    async getListConfiguration() {
      try {
        this.isLoadingBussinessList = true;
        // let date = await ApiRequest.get(`${API_ROOT}configuraciones/key/fecha_vigente_descuento`);
        // this.vigencyDate = date.lista[0].value;
        console.log(`${API_ROOT}`)
        let data = await ApiRequest.get(`${API_ROOT}preconfiguracion/getAllPreConfiguration`);
        console.log(data)
        this.itemsIngenes = data.resultado.buscarConfiguracion;
        

      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t('permissions.errorDiscounts'), ICON.ERROR);
      } finally {
        this.isLoadingBussinessList = false;
      }
    },
    async saveVigencyDate() {
      try {
        await ApiRequest.post(`${API_ROOT}configuraciones/crear`, {
          key: 'fecha_vigente_descuento',
          value: this.vigencyDate,
        });
        AlertForm(this.$i18n.t('admin.discounts.saveDate'), '', ICON.SUCCESS, 'center');
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.discounts.errorSaveDate'), ICON.ERROR);
      }
    },
    async saveDiscountNumber(row) {
      let formDiscount = {
        id: row._id,
        estado: row.estado,
        pais: row.pais == 'Mx' ? 1 : 2,
        nombre: row.nombre,
        porcentaje_dcto: row.porcentaje_dcto,
        configReglasProductos: row.configReglasProductos,
        categoria: row.categoria,
        producto: row.producto,
      };
      delete this.configDiscountUpdate[row._id];
      if (Object.keys(this.configDiscountUpdate).length === 0) this.updateInfo = false;
      try {
        await ApiRequest.put(`${API_ROOT}descuentos`, formDiscount);
        AlertForm(this.$i18n.t('admin.discounts.discountUpdate'), '', ICON.SUCCESS, 'center');
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.discounts.errorDiscountUpdate'), ICON.ERROR);
      }
    },

    deleteConfigProgram(id) {
      AlertConfirm(this.$i18n.t('admin.bussiness.msgDeleteProgram'), '', ICON.WARNING, () => this.confirmDelete(id));
    },
    async confirmDelete(id) {
      try {
        await ApiRequest.delete(`${API_ROOT}descuentos`, { id });
        AlertForm(this.$i18n.t('admin.bussiness.msgConfigRemoved'), '', ICON.SUCCESS);
        await this.getListConfiguration();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.bussiness.errorDelete'), ICON.ERROR);
      }
    },

    newProgramConfiguration() {
      this.modalShowDiscount = true;
      this.configurationForm = null;
      this.isEditConfig = false;
      this.isCloneConfig = false;
    },
    editConfigProgram(item) {
      this.modalShowDiscount = true;
      this.isEditConfig = true;
      this.isCloneConfig = false;
      this.configurationForm = item;
    },
    cloneConfigPrograms(item) {
      this.modalShowDiscount = true;
      this.isEditConfig = false;
      this.isCloneConfig = true;
      this.configurationForm = JSON.parse(JSON.stringify(item));
    },
    closeModalDiscount() {
      this.modalShowDiscount = false;
      this.configurationForm = null;
    },
    async changeStatus(item) {
      let formPrograms = {
        id: item._id,
        estado: item.estado,
        pais: item.pais == 'Mx' ? 1 : 2,
        nombre: item.nombre,
        porcentaje_dcto: item.porcentaje_dcto,
        configReglasProductos: item.configReglasProductos,
        categoria: item.categoria,
        producto: item.producto,
      };
      try {
        await ApiRequest.put(`${API_ROOT}descuentos`, formPrograms);
        AlertForm(this.$i18n.t('admin.bussiness.programsUpdate'), '', ICON.SUCCESS, 'center');
        await this.getListConfiguration();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.bussiness.errorUpdateProgram'), ICON.ERROR);
      }
    },
    saveAllDiscounts() {
      AlertConfirm(
        this.$i18n.t('admin.discounts.saveDiscounts'),
        this.$i18n.t('admin.discounts.updateDiscountsConfirm', {
          number: Object.keys(this.configDiscountUpdate).length,
        }),

        ICON.INFO,
        () => this.confirmDiscounts(),
        this.$i18n.t('admin.discounts.save')
      );
    },
    async confirmDiscounts() {
      try {
        let arrayConfigDiscounts = [];
        for (const key in this.configDiscountUpdate) {
          if (Object.hasOwnProperty.call(this.configDiscountUpdate, key)) {
            const value = this.configDiscountUpdate[key];
            arrayConfigDiscounts.push({
              id: key,
              porcentaje_dcto: value,
            });
          }
        }
        await ApiRequest.put(`${API_ROOT}descuentos/updt-many-percentage`, {
          porcentajes: arrayConfigDiscounts,
        });
        AlertForm(
          this.$i18n.t('admin.discounts.updateDiscounts', { number: arrayConfigDiscounts.length }),
          '',
          ICON.SUCCESS,
          'center'
        );
        this.configDiscountUpdate = {};
        this.updateInfo = false;
        await this.getListConfiguration();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.discounts.errorDiscountUpdate'), ICON.ERROR);
      }
    },
    updateArrayDiscounts({ _id, porcentaje_dcto }) {
      this.updateInfo = true;
      this.configDiscountUpdate[_id] = porcentaje_dcto;
    },
  },
};
</script>

<style>
.quantity {
  display: flex;
  padding: 4px;
  border: 2px solid #e5e5e5;
  background-color: rgb(246, 246, 246);
}
.quantity-group {
  width: 80px;
}

.card-discount {
  position: relative;
}

.btn-save-discounts {
  text-align: center;
  position: sticky;
  bottom: 0px;
  left: 0;
  padding: 0.5rem;
  background-color: #fffffff7;
  z-index: 3;
}
</style>
