<template>
  <div>
    <b-modal
      size="xl"
      scrollable
      v-model="modalShow"
      title="Formulario de configuración de propuesta Ingenes"
      no-close-on-backdrop
      hide-header-close
    >
      <b-row>
        <b-col cols="4">
          <label class="mt-3 font-weight-bold d-block">{{ $t('admin.role.country') }} *</label>
          <b-form-select
            v-model="formDiscount.pais"
            :options="optionsCountry"
            size="sm"
            class="mt-0 select"
            :disabled="isEdit || isClone"
          >
            <template #first>
              <b-form-select-option :value="0" disabled
                >-- {{ $t('admin.role.selectCountry') }} --</b-form-select-option
              >
            </template>
          </b-form-select>

          <label class="mt-3 font-weight-bold d-block">{{ $t('admin.discounts.name') }} *</label>
          <b-form-input
            id="name-input"
            v-model="formDiscount.nombre"
            type="text"
            size="sm"
            lazy
            :placeholder="this.$i18n.t('admin.discounts.nameConfiguration')"
          >
          </b-form-input>

          <label class="mt-3 font-weight-bold d-block">Unidad de negocio Hubspot *</label>
          <b-form-select
            v-model="formDiscount.unidad_de_negocio"
            :options="optionsUnidadNegocio"
            size="sm"
            class="mt-0 select"
            :disabled="isEdit || isClone"
          >
            <template #first>
              <b-form-select-option  disabled
                >-- Selecciona la unidad de negocio --</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col cols="4">
          <label class="mt-3 font-weight-bold">{{ $t('admin.branchMatriz.category') }} *</label>
          <b-form-select
            v-model="formDiscount.categoria"
            :options="optionsCategory"
            size="sm"
            class="mt-0 select"
            v-if="!isLoadingDone"
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- {{ $t('admin.discounts.selectCategory') }} --</b-form-select-option
              >
            </template>
          </b-form-select>
          <div v-else>
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </b-col>

        <b-col cols="4">
          <label class="mt-3 font-weight-bold">{{ $t('admin.discounts.product') }} *</label>
          <b-form-select
            v-model="formDiscount.producto"
            :options="optionsProductXCategory"
            size="sm"
            class="mt-0 select"
            v-if="!isLoadingDone"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>
                -- {{ $t('admin.discounts.selectProduct') }} --
              </b-form-select-option>
              <b-form-select-option value="all">
                {{ $t('admin.discounts.allProducts') }}
              </b-form-select-option>
            </template>
          </b-form-select>
          <div v-else>
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </b-col>
      </b-row>
      <hr />
      <template #modal-footer>
        <b-row>
          <b-col class="justify-content-end buttons-dropdown">
            <b-button class="btn btn-blue-dark-outline mr-3" @click="handleCloseModal">
              {{ $t('admin.users.cancel') }}
            </b-button>
            <b-button class="btn btn-blue-dark-outline mr-3" @click="clearProgramForm" v-if="!isEdit">
              {{ $t('admin.discounts.clear') }}
            </b-button>
            <b-button class="btn btn-blue-dark ml-0" @click="handleConfig" :disabled="isLoadingDone || !validateForm">
              <span v-if="!isLoadingDone">
                <span v-if="isEdit">{{ $t('admin.role.edit') }}</span>
                <span v-else>{{ $t('admin.role.create') }}</span>
                <b-icon icon="arrow-right" class="icon ml-2" aria-hidden="true"></b-icon>
              </span>
              <span v-else
                ><b-spinner small class="align-middle"></b-spinner>
                {{ $t('admin.medicines.loading') }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { API_ROOT } from '../../../Constants/Api';
import { AlertForm, ICON } from '../../../helpers/Alert';
import { ApiRequest } from '../../../helpers/ApiRequest';

export default {
  name: 'FormBussinessPrograms',
  components: {},
  props: ['modalShow', 'getListConfiguration', 'isEdit', 'configurationForm', 'isClone'],
  data: () => ({
    isLoadingDone: false,
    isLoadingPrograms: false,

    formDiscount: {
      pais: 0,
      nombre: null,
      estado: true,
      unidad_de_negocio: null,
      configReglasProductos: [
        {
          nombre_interno: null,
          opcion: [],
          info_interno: null,
          value: null,
        },
      ],
      porcentaje_dcto: null,
      categoria: null,
      producto: null,
    },
    // options
    optionsCountry: [
      { text: 'USA', value: 2 },
      { text: 'Mexico', value: 1 },
    ],
    optionsUnidadNegocio: [
      { text: 'Estados Unidos', value: 'Estados Unidos' },
      { text: 'Mexico', value: 'México' },
    ],
    allOptionsCategoryItem: [],
    optionsCategoryItem: [],
    optionsFilterProperty: [],
    optionsProperty: [],
    optionsCategory: [],
    optionsProductXCategory: [],
    products: null,
    filterProperty: '',
    filterOptions: '',
    optionsInternoProgram: [],
    porcentaje_dcto: null,
  }),
  async mounted() {
    try {
      let data = await ApiRequest.get(`${API_ROOT}pipelines/propsContact`);
      await this.$store.dispatch('getProductsXCountry', 1); // MX es 1
      this.optionsProperty = data.data;
      this.optionsFilterProperty = data.data;
      this.products = this.$store.state.productsXCountry;

      this.optionsCategory = Object.keys(this.products).map((el) => ({ text: el, value: el }));
    } catch (err) {
      console.log(err);

      AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.discounts.errorListContactProps'), ICON.ERROR);
    }
  },
  computed: {
    validateForm() {
      let validateFields = this.formDiscount.configReglasProductos.every(
        (el) => el.nombre_interno !== null && (el.info_interno.options.length === 0 || el.opcion.length !== 0)
      );
      return (
        this.formDiscount.pais !== 0 &&
        this.formDiscount.nombre &&
        this.formDiscount.porcentaje_dcto !== '' &&
        this.formDiscount.configReglasProductos.length > 0 &&
        validateFields
      );
    },
    placeholderText() {
      return this.$i18n.t('welcome.search');
    },
    placeholderProperty() {
      return this.$i18n.t('admin.bussiness.PropertyValue');
    },
    textDropdown() {
      return this.$i18n.t('admin.pharmacy.select');
    },
    selectCategory() {
      return this.formDiscount.categoria;
    },
  },
  watch: {
    porcentaje_dcto() {
      if (!this.porcentaje_dcto) this.formDiscount.porcentaje_dcto = '';
      else if (Number(this.porcentaje_dcto) > 100) {
        this.formDiscount.porcentaje_dcto = 100;
        this.porcentaje_dcto = 100;
      } else if (Number(this.porcentaje_dcto) < 0) {
        this.formDiscount.porcentaje_dcto = 0;
        this.porcentaje_dcto = 0;
      } else {
        this.formDiscount.porcentaje_dcto = Number(this.porcentaje_dcto);
      }
    },
    selectCategory() {
      if (this.selectCategory) {
        let codigoAlternoProducto = this.products[this.selectCategory].map((el) => el.split('|')[1]);
        this.optionsProductXCategory = JSON.parse(JSON.stringify(codigoAlternoProducto));
      }
    },
    configurationForm() {
      if (this.configurationForm) {
        this.formDiscount = {
          pais: this.configurationForm.pais === 'Mx' ? 1 : 2,
          porcentaje_dcto: this.configurationForm.porcentaje_dcto,
          nombre: this.isClone ? null : this.configurationForm.nombre,
          configReglasProductos: this.configurationForm.configReglasProductos,
          producto: this.configurationForm.producto,
          categoria: this.configurationForm.categoria,
          id: this.configurationForm._id,
          estado: this.configurationForm.estado,
        };
        this.porcentaje_dcto = this.formDiscount.porcentaje_dcto;
        this.optionsInternoProgram = this.formDiscount.configReglasProductos.map((el) =>
          el.info_interno.options.map((el) => ({
            text: el.value,
            value: el.value,
          }))
        );
      }
    },

    filterOptions() {
      this.optionsCategoryItem = this.allOptionsCategoryItem.filter((el) =>
        el.text.toLowerCase().includes(this.filterOptions)
      );
    },

    filterProperty() {
      this.optionsFilterProperty = this.optionsProperty.filter((el) =>
        el.label.toLowerCase().includes(this.filterProperty)
      );
    },
  },

  methods: {
    async handleConfig() {
      this.isLoadingDone = true;
      //validar config de reglas
      for (const item of this.formDiscount.configReglasProductos) {
        if (item.opcion.length) {
          item.value = null;
        }
      }
      if (this.isEdit) {
        try {
          await ApiRequest.put(`${API_ROOT}descuentos`, this.formDiscount);
          AlertForm(this.$i18n.t('admin.discounts.discountUpdate'), '', ICON.SUCCESS, 'center');
          this.clearProgramForm();
          this.getListConfiguration();
          this.$emit('closeModal');
        } catch (err) {
          AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.discounts.errorDiscountUpdate'), ICON.ERROR);
        } finally {
          this.isLoadingDone = false;
        }
      } else {
        try {
          await ApiRequest.post(`${API_ROOT}descuentos`, this.formDiscount);
          AlertForm(this.$i18n.t('admin.bussiness.programsCreate'), '', ICON.SUCCESS, 'center');
          this.clearProgramForm();
          this.getListConfiguration();
          this.$emit('closeModal');
        } catch (err) {
          if (err.status === 500 && err.data.msg.includes('duplicate')) {
            AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.bussiness.duplicateProgram'), ICON.ERROR);
          } else {
            AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.bussiness.errorCreateProgram'), ICON.ERROR);
          }
        } finally {
          this.isLoadingDone = false;
        }
      }
    },

    async getProductsXCountry(pais) {
      try {
        this.isLoadingPrograms = true;
        this.optionsCategoryItem = [];
        const data = await ApiRequest.post(`${API_ROOT}cards/productosCategoriaPais`, {
          localidad: pais,
        });
        let allProductsBec = data.categorias_productos.BEC || data.categorias_productos['BEC INTERNACIONAL'];
        this.optionsCategoryItem = allProductsBec.map((el) => {
          return {
            text: el,
            value: el.split('|')[0],
          };
        });
        this.allOptionsCategoryItem = this.optionsCategoryItem;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.top.errorProducts'), ICON.ERROR);
      } finally {
        this.isLoadingPrograms = false;
      }
    },

    addPropertyProgram() {
      this.formDiscount.configReglasProductos.push({
        nombre_interno: null,
        opcion: [],
        info_interno: null,
        value: null,
      });
    },
    deletePropertyProgram(index) {
      this.formDiscount.configReglasProductos.splice(index, 1);
      this.optionsInternoProgram.splice(index, 1);
    },

    propertyProgramsSelected(item, condition, index) {
      condition.opcion = [];
      let dataOption = item.options.map((el) => ({
        text: el.value,
        value: el.value,
      }));
      condition.info_interno = item;
      condition.nombre_interno = item.name;
      if (dataOption.length === 0) condition.opcion = [];
      this.optionsInternoProgram.splice(index, 1, dataOption);
    },
    selectAllOptions(programs, condition) {
      condition.opcion = [];
      for (let i in programs) {
        condition.opcion.push(programs[i].value);
      }
    },
    unSelectAllOptions(condition) {
      condition.opcion = [];
    },
    handleCloseModal() {
      this.clearProgramForm();
      this.$emit('closeModal');
    },
    /* limpiar el form */
    clearProgramForm() {
      this.formDiscount = {
        pais: 0,
        nombre: null,
        estado: true,
        configReglasProductos: [
          {
            nombre_interno: null,
            opcion: [],
            info_interno: null,
            value: null,
          },
        ],
        porcentaje_dcto: null,
        categoria: null,
        producto: null,
      };
      this.optionsCategoryItem = [];
      this.optionsInternoProgram = [];
    },
  },
};
</script>

<style scoped>
@import '../../../assets/css/global.css';

.custom-actions {
  cursor: pointer;
}

.custom-card-category {
  max-height: 250px;
  overflow-y: auto;
}

.custom-buttom:disabled {
  cursor: not-allowed;
}

.config-bussiness /deep/ .btn-group {
  width: 100%;
}
.config-bussiness /deep/ .btn-group > button {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.config-bussiness /deep/ .dropdown-menu.show {
  width: 100% !important;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 0.9rem;
}

.groupSearch {
  padding: 0 1rem;
}

.icon_link {
  cursor: pointer;
  opacity: 0.75;
}
/* delete */
.delete-item:hover {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}

.rol .configuracion {
  margin-top: 4rem;
}

.rol /deep/ .headerClass {
  color: var(--gray-color);
  font-size: 0.8rem;
}

.modal-config label {
  font-weight: bold;
  margin-top: 1rem;
}
</style>
